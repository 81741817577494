@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Design tokens */
    --spacing-base: 1rem;
    --radius-sm: 0.375rem;
    --radius-md: 0.5rem;
    --radius-lg: 1rem;
    --radius-xl: 2rem;
    --radius-full: 9999px;
    
    /* Theme - Light */
    --color-bg-primary: #FFFFFF;
    --color-bg-secondary: #F8FAFC;
    --color-bg-tertiary: #F1F5F9;
    --color-text-primary: #0F172A;
    --color-text-secondary: #475569;
    --color-accent: #0284c7;
    --color-accent-light: #38bdf8;
    
    /* Status colors */
    --color-success: #22C55E;
    --color-success-light: #86EFAC;
    --color-success-bg: #F0FDF4;
    --color-success-text: #166534;
    --color-error: #EF4444;
    --color-error-light: #FCA5A5;
    --color-error-bg: #FEF2F2;
    --color-error-text: #991B1B;
    
    /* UI elements */
    --color-border: #E2E8F0;
    --color-hover: #EFF6FF;
    --color-card-shadow: rgba(0, 0, 0, 0.05);
    --color-overlay-light: rgba(255, 255, 255, 0.1);
    --color-overlay-dark: rgba(0, 0, 0, 0.1);
    --color-blur-bg: rgba(191, 219, 254, 0.5);

    /* Service specific */
    --color-service-card: #FFFFFF;
    --color-service-card-hover: #F8FAFC;
    --color-service-section: #EEF2FF;
    --color-service-highlight-bg: rgba(2, 132, 199, 0.1);
    --color-service-highlight-text: #0284c7;
    --color-service-highlight-ring: rgba(2, 132, 199, 0.3);

    /* Areas specific */
    --color-areas-bg: #F1F5F9;
    --color-areas-card: #FFFFFF;
    --color-areas-card-hover: #F8FAFC;
    --color-areas-highlight: #E0F2FE;

    /* Service secondary cards */
    --color-service-secondary-bg: #E2E8F0;
    --color-service-secondary-card: #FFFFFF;
    --color-service-secondary-hover: #F1F5F9;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --color-bg-primary: #0F172A;
      --color-bg-secondary: #1E293B;
      --color-bg-tertiary: #334155;
      --color-text-primary: #F8FAFC;
      --color-text-secondary: #CBD5E1;
      --color-accent: #38bdf8;
      --color-accent-light: #7dd3fc;
      
      --color-success: #4ADE80;
      --color-success-light: #86EFAC;
      --color-success-bg: rgba(74, 222, 128, 0.1);
      --color-success-text: #A7F3D0;
      --color-error: #F87171;
      --color-error-light: #FECACA;
      --color-error-bg: rgba(248, 113, 113, 0.1);
      --color-error-text: #FECACA;
      
      --color-border: #334155;
      --color-hover: #1E293B;
      --color-card-shadow: rgba(0, 0, 0, 0.2);
      --color-overlay-light: rgba(255, 255, 255, 0.05);
      --color-overlay-dark: rgba(0, 0, 0, 0.2);
      --color-blur-bg: rgba(30, 58, 138, 0.5);
      
      --color-service-card: #1E293B;
      --color-service-card-hover: #334155;
      --color-service-section: #0B1222;
      --color-service-highlight-bg: rgba(56, 189, 248, 0.1);
      --color-service-highlight-text: #38bdf8;
      --color-service-highlight-ring: rgba(56, 189, 248, 0.3);

      --color-areas-bg: #1E293B;
      --color-areas-card: #475569;
      --color-areas-card-hover: #64748B;
      --color-areas-highlight: #0EA5E9;

      --color-service-secondary-bg: #0F172A;
      --color-service-secondary-card: #1E293B;
      --color-service-secondary-hover: #334155;
    }
  }

  /* Theme 0 - Default */
  .theme-0 {
    --color-bg-primary: #FFFFFF;
    --color-bg-secondary: #F8FAFC;
    --color-bg-tertiary: #F1F5F9;
    --color-text-primary: #0F172A;
    --color-text-secondary: #475569;
    --color-accent: #0284c7;
    --color-accent-light: #38bdf8;

    --color-service-section: #EEF2FF;
    --color-service-card: #FFFFFF;
    --color-service-card-hover: #F8FAFC;
    --color-service-highlight-bg: rgba(2, 132, 199, 0.1);
    --color-service-highlight-text: #0284c7;
    --color-service-highlight-ring: rgba(2, 132, 199, 0.3);

    --color-areas-bg: #F1F5F9;
    --color-areas-card: #FFFFFF;
    --color-areas-card-hover: #F8FAFC;
    --color-areas-highlight: #E0F2FE;

    --color-service-secondary-bg: #E2E8F0;
    --color-service-secondary-card: #FFFFFF;
    --color-service-secondary-hover: #F1F5F9;
    font-family: 'Inter', sans-serif;
  }

  @media (prefers-color-scheme: dark) {
    .theme-0 {
      --color-bg-primary: #0F172A;
      --color-bg-secondary: #1E293B;
      --color-bg-tertiary: #334155;
      --color-text-primary: #F8FAFC;
      --color-text-secondary: #CBD5E1;
      --color-accent: #38bdf8;
      --color-accent-light: #7dd3fc;

      --color-service-section: #0B1222;
      --color-service-card: #1E293B;
      --color-service-card-hover: #334155;
      --color-service-highlight-bg: rgba(56, 189, 248, 0.1);
      --color-service-highlight-text: #38bdf8;
      --color-service-highlight-ring: rgba(56, 189, 248, 0.3);

      --color-areas-bg: #1E293B;
      --color-areas-card: #475569;
      --color-areas-card-hover: #64748B;
      --color-areas-highlight: #0EA5E9;

      --color-service-secondary-bg: #0F172A;
      --color-service-secondary-card: #1E293B;
      --color-service-secondary-hover: #334155;
    }
  }

  /* Theme 1 - Warm */
  .theme-1 {
    --color-bg-primary: #fbf9f6;
    --color-bg-secondary: #fefbf6;
    --color-bg-tertiary: #f3e8be;
    --color-text-primary: #13120f;
    --color-text-secondary: #332e22;
    --color-accent: #ff8717;
    --color-accent-light: #ffa64d;

    --color-service-section: #fefbf6;
    --color-service-card: #fbf9f6;
    --color-service-card-hover: #f3e8be;
    --color-service-highlight-bg: rgba(255, 135, 23, 0.1);
    --color-service-highlight-text: #ff8717;
    --color-service-highlight-ring: rgba(255, 135, 23, 0.3);

    --color-areas-bg: #f3e8be;
    --color-areas-card: #fbf9f6;
    --color-areas-card-hover: #fefbf6;
    --color-areas-highlight: #ffe8d4;

    --color-service-secondary-bg: #f3e8be;
    --color-service-secondary-card: #fbf9f6;
    --color-service-secondary-hover: #fefbf6;

    font-family: 'Times New Roman', serif;
  }

  @media (prefers-color-scheme: dark) {
    .theme-1 {
      --color-bg-primary: #2A1810;
      --color-bg-secondary: #3B2218;
      --color-bg-tertiary: #4D2D1F;
      --color-text-primary: #FBF9F6;
      --color-text-secondary: #E5DDD3;
      --color-accent: #FF9F40;
      --color-accent-light: #FFB366;

      --color-service-section: #3B2218;
      --color-service-card: #4D2D1F;
      --color-service-card-hover: #5f3828;
      --color-service-highlight-bg: rgba(255, 159, 64, 0.1);
      --color-service-highlight-text: #FF9F40;
      --color-service-highlight-ring: rgba(255, 159, 64, 0.3);

      --color-areas-bg: #4D2D1F;
      --color-areas-card: #3B2218;
      --color-areas-card-hover: #5f3828;
      --color-areas-highlight: #ff9f80;

      --color-service-secondary-bg: #2A1810;
      --color-service-secondary-card: #3B2218;
      --color-service-secondary-hover: #4D2D1F;
    }
  }

  /* Theme 2 - Purple */
  .theme-2 {
    --color-bg-primary: #f0f0ff;
    --color-bg-secondary: #e7e7ff;
    --color-bg-tertiary: #d0d0ff;
    --color-text-primary: #1b1b33;
    --color-text-secondary: #333366;
    --color-accent: #aa66ff;
    --color-accent-light: #bf8fff;

    --color-service-section: #e7e7ff;
    --color-service-card: #f0f0ff;
    --color-service-card-hover: #d0d0ff;
    --color-service-highlight-bg: rgba(170, 102, 255, 0.1);
    --color-service-highlight-text: #aa66ff;
    --color-service-highlight-ring: rgba(170, 102, 255, 0.3);

    --color-areas-bg: #d0d0ff;
    --color-areas-card: #f0f0ff;
    --color-areas-card-hover: #e7e7ff;
    --color-areas-highlight: #dcdcff;

    --color-service-secondary-bg: #d0d0ff;
    --color-service-secondary-card: #f0f0ff;
    --color-service-secondary-hover: #e7e7ff;

    font-family: 'Comic Sans MS', cursive;
  }

  @media (prefers-color-scheme: dark) {
    .theme-2 {
      --color-bg-primary: #1B1B33;
      --color-bg-secondary: #2A2A4D;
      --color-bg-tertiary: #333366;
      --color-text-primary: #F0F0FF;
      --color-text-secondary: #D0D0FF;
      --color-accent: #C299FF;
      --color-accent-light: #D4B3FF;

      --color-service-section: #2A2A4D;
      --color-service-card: #333366;
      --color-service-card-hover: #44447a;
      --color-service-highlight-bg: rgba(194, 153, 255, 0.1);
      --color-service-highlight-text: #C299FF;
      --color-service-highlight-ring: rgba(194, 153, 255, 0.3);

      --color-areas-bg: #333366;
      --color-areas-card: #2A2A4D;
      --color-areas-card-hover: #44447a;
      --color-areas-highlight: #8F8FFF;

      --color-service-secondary-bg: #1B1B33;
      --color-service-secondary-card: #2A2A4D;
      --color-service-secondary-hover: #333366;
    }
  }

  /* Theme 3 - Mint */
  .theme-3 {
    --color-bg-primary: #e6fff8;
    --color-bg-secondary: #ccfff0;
    --color-bg-tertiary: #99ffe6;
    --color-text-primary: #0d332e;
    --color-text-secondary: #1a665d;
    --color-accent: #00ffcc;
    --color-accent-light: #33ffd6;

    --color-service-section: #ccfff0;
    --color-service-card: #e6fff8;
    --color-service-card-hover: #99ffe6;
    --color-service-highlight-bg: rgba(0, 255, 204, 0.1);
    --color-service-highlight-text: #00ffcc;
    --color-service-highlight-ring: rgba(0, 255, 204, 0.3);

    --color-areas-bg: #99ffe6;
    --color-areas-card: #e6fff8;
    --color-areas-card-hover: #ccfff0;
    --color-areas-highlight: #8efce5;

    --color-service-secondary-bg: #99ffe6;
    --color-service-secondary-card: #e6fff8;
    --color-service-secondary-hover: #ccfff0;

    font-family: 'Courier New', monospace;
  }

  @media (prefers-color-scheme: dark) {
    .theme-3 {
      --color-bg-primary: #0D332E;
      --color-bg-secondary: #1A665D;
      --color-bg-tertiary: #238F83;
      --color-text-primary: #E6FFF8;
      --color-text-secondary: #CCFFF0;
      --color-accent: #40FFD9;
      --color-accent-light: #66FFE0;

      --color-service-section: #1A665D;
      --color-service-card: #238F83;
      --color-service-card-hover: #2a9e92;
      --color-service-highlight-bg: rgba(64, 255, 217, 0.1);
      --color-service-highlight-text: #40FFD9;
      --color-service-highlight-ring: rgba(64, 255, 217, 0.3);

      --color-areas-bg: #238F83;
      --color-areas-card: #1A665D;
      --color-areas-card-hover: #2a9e92;
      --color-areas-highlight: #40FFD9;

      --color-service-secondary-bg: #0D332E;
      --color-service-secondary-card: #1A665D;
      --color-service-secondary-hover: #238F83;
    }
  }

  /* Theme 4 - Orange */
  .theme-4 {
    --color-bg-primary: #fff2e6;
    --color-bg-secondary: #ffe6cc;
    --color-bg-tertiary: #ffdbb3;
    --color-text-primary: #331a00;
    --color-text-secondary: #4d2600;
    --color-accent: #ff8c1a;
    --color-accent-light: #ffa64d;

    --color-service-section: #ffe6cc;
    --color-service-card: #fff2e6;
    --color-service-card-hover: #ffdbb3;
    --color-service-highlight-bg: rgba(255, 140, 26, 0.1);
    --color-service-highlight-text: #ff8c1a;
    --color-service-highlight-ring: rgba(255, 140, 26, 0.3);

    --color-areas-bg: #ffdbb3;
    --color-areas-card: #fff2e6;
    --color-areas-card-hover: #ffe6cc;
    --color-areas-highlight: #ffe0cc;

    --color-service-secondary-bg: #ffdbb3;
    --color-service-secondary-card: #fff2e6;
    --color-service-secondary-hover: #ffe6cc;

    font-family: 'Arial Black', sans-serif;
  }

  @media (prefers-color-scheme: dark) {
    .theme-4 {
      --color-bg-primary: #331A00;
      --color-bg-secondary: #4D2600;
      --color-bg-tertiary: #663300;
      --color-text-primary: #FFF2E6;
      --color-text-secondary: #FFE6CC;
      --color-accent: #FFA64D;
      --color-accent-light: #FFB366;

      --color-service-section: #4D2600;
      --color-service-card: #663300;
      --color-service-card-hover: #7a3b00;
      --color-service-highlight-bg: rgba(255, 166, 77, 0.1);
      --color-service-highlight-text: #FFA64D;
      --color-service-highlight-ring: rgba(255, 166, 77, 0.3);

      --color-areas-bg: #663300;
      --color-areas-card: #4D2600;
      --color-areas-card-hover: #7a3b00;
      --color-areas-highlight: #ffb366;

      --color-service-secondary-bg: #331A00;
      --color-service-secondary-card: #4D2600;
      --color-service-secondary-hover: #663300;
    }
  }
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  font-family: 'Inter', sans-serif;
}

* {
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}